<template>
  <div id="addMoDular">
    <div class="headerDetail">
      <ds-header :title="headerTitle"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" labelAlign="right" :labelCol="{ style: 'width: 240px' }" :rules="rules">
      <div style="margin-top: 20px">
        <div class="content">
            <a-form-model-item label="所属项目：" prop="projectId" :span="24">
                <a-select
                  :disabled='disabled'
                  class="indexClass"
                  placeholder="请选择"
                  v-model="form.projectId"
                >
                  <a-select-option v-for="item in projectName" :key="item.id" :value="item.id">
                    {{ item.project_name }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="页面位置：" prop="pagePosition" :span="24">
                <a-select
                    :disabled='disabled'
                    class="indexClass"
                    placeholder="请选择"
                    v-model="form.pagePosition"
                >
                    <a-select-option v-for="item in pagePositionList" :key="item.id" :value="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="模块名称：" prop="moduleName" :span="24">
                <a-input
                    :disabled='disabled'
                    class="indexClass"
                    placeholder="请输入模块名称"
                    v-model.trim="form.moduleName"
                />
            </a-form-model-item>
            <a-form-model-item label="模块名称是否在页面展示：" prop="show" :span="24">
                <a-radio-group name="radioGroup" v-model="form.show" :disabled='disabled'>
                    <a-radio :value="0"> 是 </a-radio>
                    <a-radio :value="1"> 否 </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="模块副标题：" prop="childModuleName" :span="24">
                <a-input
                    :disabled='disabled'
                    class="indexClass"
                    placeholder="请输入模块副标题"
                    v-model.trim="form.childModuleName"
                />
            </a-form-model-item>
            <a-form-model-item label="模块排序：" prop="sort" :span="24">
                <a-input-number
                    :disabled='disabled'
                    :max="99999"
                    placeholder="请输入优先级序号"
                    v-model.trim="form.sort"
                />
            </a-form-model-item>
            <a-form-model-item label="模块图标：" prop="iconUrl">
                <!-- <a-upload
                    list-type="picture-card"
                    class="avatar-uploader"
                    :action="IMG_API + '/oss/files'"
                    :before-upload="beforeUpload"
                    @preview="handlePreview"
                    @change="handleChange"
                >
                    <img class="upImg" v-if="this.form.iconUrl" :src="this.form.iconUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                    </div>
                </a-upload> -->
                <a-upload
                  name="file"
                  list-type="picture-card"
                  :action="IMG_API + '/oss/files'"
                  class="avatar-uploader"
                  :file-list="iconUrl"
                  @change="handleChange"
                  @preview="handlePreview"
                  :before-upload="beforeUpload"
                >
                  <div v-if="this.iconUrl.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
                <p class="tip">请上传尺寸24*24，格式为PNG、JPG的图片</p>
            </a-form-model-item>
            <!-- <a-form-model-item label="模块类型：" prop="moduleType" :span="24">
                <a-select
                    class="indexClass"
                    placeholder="请选择"
                    v-model="form.moduleType"
                >
                    <a-select-option v-for="item in projectName" :key="item.id" :value="item.id">
                        {{ item.project_name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item> -->
            <a-form-model-item label="模块类型" prop="moduleType" :span="24">
                <a-radio-group name="radioGroup" v-model="form.moduleType" :disabled='disabled'>
                    <a-radio :value="0"> 四宫格 </a-radio>
                    <a-radio :value="1"> 常用服务 </a-radio>
                    <a-radio :value="2"> 一般服务 </a-radio>
                    <a-radio :value="3"> banner </a-radio>
                    <a-radio :value="4"> 广告位 </a-radio>
                    <a-radio :value="5"> 活动 </a-radio>
                    <a-radio :value="6"> 权益 </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="页面信息配置:" prop="information" :span="24" v-if="form.moduleType === 0">
                <!-- 信息填写展示组件 -->
                <div class="informationBox">
                    <div v-for="(item, index) in contentList" :key="index">
                        <contentMessage
                            ref="contentMessage"
                            :serviceList="serviceList"
                            :authenticationPeople="authenticationPeople"
                            :form="item"
                            :disable="disabled"
                            @contentMessage="contentMessage"
                        ></contentMessage>
                    </div>
                </div>
            </a-form-model-item>
            <a-form-model-item label="模块样式：" prop="moduleStyle" :span="24" v-if="form.moduleType === 2">
                <a-select
                    class="indexClass"
                    placeholder="请选择"
                    :disabled='disabled'
                    v-model="form.moduleStyle"
                >
                    <a-select-option v-for="item in modularCssList" :key="item.id" :value="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="状态：" prop="status" :span="24">
                <a-radio-group name="radioGroup" v-model="form.status" :disabled='disabled'>
                    <a-radio :value="0"> 启用 </a-radio>
                    <a-radio :value="1"> 禁用 </a-radio>
                </a-radio-group>
            </a-form-model-item>
        </div>
      </div>
    </a-form-model>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <div class="footer">
      <a-button key="submit" style="margin-right: 30px" type="primary" v-if="$route.query.type != 'view'" @click="handleOk">保存
      </a-button>
    </div>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import contentMessage from "../components/content.vue";
import * as api from "@/api/modularManagement";

export default {
  components: {
    contentMessage,
  },
  data() {
    return {
      rules: {
        projectId: [{ required: true, message: "请选择所属项目", trigger: "blur" }],
        pagePosition: [{ required: true, message: "请选择页面位置", trigger: "blur" }],
        moduleName: [{ required: true, message: "请输入模块标题", trigger: "blur" }],
        show: [{ required: true, message: "", trigger: "change" }],
        sort: [{ required: true, message: "请输入模块排序", trigger: "change" }],
        moduleType: [{ required: true, message: "请选择模块类型", trigger: "change" }],
        information: [{ required: true, message: "", trigger: "change" }],
        status: [{ required: true, message: "", trigger: "change" }],
        moduleStyle: [{ required: true, message: "请选择模块样式", trigger: "blur" }],
      },
      headerTitle: "新建模块",
      IMG_API: IMG_API,
      pagePositionList: [{id: 0, name: "首页"},{id: 1, name: "食住行"},{id: 2, name: "无忧创业"},{id: 3, name: "我的权益"}],
      modularCssList: [
        {id: 0, name: "类型1 80*80（首页金刚区模板）"},
        {id: 1, name: "类型2 80*80（食住行页面模板1）"},
        {id: 2, name: "类型3 312*156（食住行/无忧创业页面模板2）"},
        {id: 4, name: "类型4 312*156（食住行/无忧创业页面模板3）"},
        {id: 5, name: "类型5 312*156（食住行/无忧创业页面模板4）"},
        {id: 3, name: "类型6 312*156（食住行页面模板6）"}
      ],
      form: {
        projectId: undefined, // 所属项目
        pagePosition: undefined, // 页面位置
        moduleName: "", // 模块名称
        show: 1, // 模块名称是否在首页展示
        childModuleName: "", // 模块副标题
        sort: 0, // 模块排序
        iconUrl: "", // 模块图标
        moduleType: 0, // 模块类型
        status: 1, // 状态
        information: "1",
        moduleStyle: undefined, // 模块样式
      },
      iconUrl: [],
      checkSize: false, //校验图片大小
      checkFormat: false, //校验格式
      previewVisible: false, //预览弹窗开关
      previewImage: "", //预览图片地址
      projectName: [], // 项目列表
      loading: false, // 上传图片加载
      serviceList: [], // 服务列表
      authenticationPeople: [{
        value: 1, 
        label: "科创人才"
      },{
        value: 2, 
        label: "服务团队"
      },{
        value: 3, 
        label: "重点客户"
      },{
        value: 4, 
        label: "普通用户"
      },], // 企业认证人群限制
      contentList: [{
        title: "左一", //子组件标题
        pageLocation: 0,
        width: 340, //图片宽度
        height: 340, //图片高度
        pageType: 0, // 模块类型
        serviceId: "", //服务ID
        h5Url: "", //H5链接
        pageImage: [], //图片路径
        userIdentity: 1, // 用户身份限制
        enterpriseUser: [], // 企业认证人群限制
        pageStatus: 1, // 页面状态
        prompt: "", // 提示语
        pageName: "",
        imgUrl: "",
        configType: 0,
        android: 'SDK',
        ios: 'SDK',
        androidUrl: '',
        iosUrl: '',
      },{
        title: "右一", //子组件标题
        pageLocation: 1,
        width: 340, //图片宽度
        height: 340, //图片高度
        pageType: 0, // 模块类型
        serviceId: "", //服务ID
        h5Url: "", //H5链接
        pageImage: [], //图片路径
        userIdentity: 1, // 用户身份限制
        enterpriseUser: [], // 企业认证人群限制
        pageStatus: 1, // 页面状态
        prompt: "", // 提示语
        pageName: "",
        imgUrl: "",
        configType: 0,
        android: 'SDK',
        ios: 'SDK',
        androidUrl: '',
        iosUrl: '',
      },{
        title: "左二", //子组件标题
        pageLocation: 2,
        width: 340, //图片宽度
        height: 340, //图片高度
        pageType: 0, // 模块类型
        serviceId: "", //服务ID
        h5Url: "", //H5链接
        pageImage: [], //图片路径
        userIdentity: 1, // 用户身份限制
        enterpriseUser: [], // 企业认证人群限制
        pageStatus: 1, // 页面状态
        prompt: "", // 提示语
        pageName: "",
        imgUrl: "",
        configType: 0,
        android: 'SDK',
        ios: 'SDK',
        androidUrl: '',
        iosUrl: '',
      },{
        title: "右二", //子组件标题
        pageLocation: 3,
        width: 340, //图片宽度
        height: 340, //图片高度
        pageType: 0, // 模块类型
        serviceId: "", //服务ID
        h5Url: "", //H5链接
        pageImage: [], //图片路径
        userIdentity: 1, // 用户身份限制
        enterpriseUser: [], // 企业认证人群限制
        pageStatus: 1, // 页面状态
        prompt: "", // 提示语
        pageName: "",
        imgUrl: "",
        configType: 0,
        android: 'SDK',
        ios: 'SDK',
        androidUrl: '',
        iosUrl: '',
      }],
      contentValueList: [], // 信息填写提交值
      disabled: false
    };
  },
  created() {
    // 服务列表
    this.getServiceList();
    // 项目列表
    this.projectNameList();
    
    // this.$route.query.type
    if(this.$route.query.id) {
      this.getInfomation();
    }
    if(this.$route.query.type == 'update') {
      this.headerTitle = "修改模块";
    }
    if(this.$route.query.type == 'view') {
      this.disabled = true;
      this.headerTitle = "查看模块";
    }
  },
  methods: {
    // 详情
    async getInfomation() {
      const res = await api.modularInfo(this.$route.query.id);
      if(res.data.iconUrl) {
        var img = {
          uid: "1",
          name: "image.png",
          status: "done",
          url: res.data.iconUrl,
        };
        this.iconUrl = [img];
      }
      console.log(res.data);
      this.form = res.data;
      this.form.information = "1";
      if(res.data.appModuleHomes.length > 0) {
        // this.contentList = [];
        
        this.contentList.map(item => {
           res.data.appModuleHomes.map(items => {
            if(item.pageLocation == items.pageLocation) {
              item.id = items.id
              item.pageType = items.pageType;
              if(item.pageType == 0) {
                item.serviceId = items.text;
              } else {
                item.h5Url = items.text;
              }
              if(items.enterpriseUser) {
                items.enterpriseUser = items.enterpriseUser.split(',');
                items.enterpriseUser.forEach((itemsOne,index) =>{
                  items.enterpriseUser[index] = parseInt(items.enterpriseUser[index])
                })
                item.enterpriseUser = items.enterpriseUser;
              }
              item.userIdentity = items.userIdentity;
              item.pageStatus = items.pageStatus;
              item.prompt = items.prompt;
              item.pageName = items.pageName;
              item.imgUrl = items.pageImage;
              item.configType = items.configType;
              console.log(item.android)
              if(items.android === "SDK") {
                item.android = items.android
                item.androidUrl = "";
              } else {
                item.android = 'url';
                item.androidUrl = items.android;
              }

              if(items.ios === "SDK") {
                item.ios = items.ios
                item.iosUrl = ""
              } else {
                item.ios = 'url';
                item.iosUrl = items.ios;
              }
              var obj = {
                uid: "1",
                name: "image.png",
                status: "done",
                url: items.pageImage,
              };
              item.pageImage = [obj];
            }
           })
        })
        // res.data.appModuleHomes.map(item => {
        //   item.width = 340;
        //   item.height = 340;

        //   if(item.enterpriseUser) {
        //     item.enterpriseUser = item.enterpriseUser.split(',');
        //     item.enterpriseUser.forEach((items,index) =>{
        //       item.enterpriseUser[index] = parseInt(item.enterpriseUser[index])
        //     })
        //   } else {
        //     item.enterpriseUser = [];
        //   }

        //   if(item.pageType == 0) {
        //     item.serviceId = item.text;
        //   } else {
        //     item.h5Url = item.text;
        //   }
        //   if(item.pageLocation == 0) {
        //     item.title = "左一"
        //   } else if(item.pageLocation == 1) {
        //     item.title = "左二"
        //   } else if(item.pageLocation == 2) {
        //     item.title = "右一"
        //   } else if(item.pageLocation == 3) {
        //     item.title = "右二"
        //   }
        //   var obj = {
        //     uid: "1",
        //     name: "image.png",
        //     status: "done",
        //     url: item.pageImage,
        //   };
        //   item.pageImage = [obj];
        // })
        // console.log("1111",res.data.appModuleHomes)
        // this.contentList = res.data.appModuleHomes
      }
      
      // console.log(res);
      
    },

    // 提交
    async handleOk() {
      // 触发组件的校验规则
      let that = this;
      new Promise(function (resolve, reject) {
        if(that.form.moduleType === 0) {
          //清空提交信息
          that.contentValueList = [];
          //触发每个组件的校验事件
          that.$refs.ruleForm.validate(async (valid) => {
            if (valid) {
              // 校验每个组件的必填项
              if (that.$refs.contentMessage) {
                that.$refs.contentMessage.forEach(async (item) => {
                  await item.parentHandleclick();
                });
                resolve();
              }
            }
          });
        } else {
          that.$refs.ruleForm.validate(async (valid) => {
            if (valid) {
              resolve();
            }
          });
          
        }
      }).then(() => {
        if(that.form.moduleType === 0) {
          let type = that.contentValueList.every((item) => {
            return item.type === true;
          });
          if (type) {
            that.submitNewData();
          }
        } else {
          that.submitNewData();
        }
      });
    },
    
    // 提交
    async submitNewData() {
      if(this.form.moduleType === 0) {
        var list = [];
        //信息填写赋值
        this.contentValueList.forEach((item) => {
          console.log(item.form);
          if(item.form.configType == 1){
            if(!item.form.android) {
              this.$message.error("请输入android链接！");
              throw new Error('stop');
            }
            if(!item.form.ios) {
              this.$message.error("请输入ios链接！");
              throw new Error('stop');
            }
          }
          if(item.form.enterpriseUser.length > 0) {
            item.form.enterpriseUser = item.form.enterpriseUser.join(',')
          } else {
            item.form.enterpriseUser = null;
          }
          list.push(item.form);
        });
       
        
        this.form.appModuleHomes = list;
      } else {
        this.form.appModuleHomes = null;
      }
      console.log(this.iconUrl) 
      if(this.iconUrl.length > 0) {
        this.form.iconUrl = this.iconUrl[0].response ? this.iconUrl[0].response.redirect_uri : this.iconUrl[0].url;
      } else {
        this.form.iconUrl = "";
      }
      if(this.$route.query.id) {
        this.form.id = this.$route.query.id;
        const res = await api.modularUpdate(this.form);
        if (res.code === "200") {
          this.$message.success("提交成功!");
          this.$router.go(-1);
        } else {
          this.$message.error(res.msg);
        }
      } else {
        const res = await api.modularAdd(this.form);
        if (res.code === "200") {
          this.$message.success("提交成功!");
          this.$router.go(-1);
        } else {
          this.$message.error(res.msg);
        }
      }

    },

    //获取组件内填写值
    contentMessage(val) {
      this.contentValueList.push(val);
    },

    // 服务列表
    async getServiceList() {
      const res = await api.selectservicelistbyname();
      if (res.code === "200") {
        res.data.forEach((item) => {
          item.serviceName = item.serviceNumber + "(" + item.serviceName + ")";
        });
        this.serviceList = res.data;
      }
    },

    // 项目列表
    async projectNameList() {
      const res = await api.projectName();
      if (res.code == 200) {
        this.projectName = res.data.items;
      }
    },  
    // 图片上传
    handleChange({ fileList }) {
      if (this.checkSize) {
        this.iconUrl = fileList;
      } else {
        this.iconUrl = [];
      }
    },

    // 图片限制
    beforeUpload(file) {
      this.checkSize = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg" || type === "jpeg";
      if (!isJPG) {
        this.$message.error("上传图片只能是jpg、png格式!");
        return false;
      }
      const width = 24; // 获取图片的限制宽度
      const height = 24; //获取图片的限制高度
      this.checkSize = isJPG;
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          this.checkSize = false;
          return reject();
        }
      );
      return isJPG && isSize;
    },

     //预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //弹窗图片预览关闭弹窗
    previewHandleCancel() {
      this.previewVisible = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.indexClass {
  width: 330px;
}

.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

::v-deep .ant-form-item {
  display: flex;
  // margin-bottom: 0;
}

::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.content {
  /* margin-left:24px; */
  margin-top: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
::v-deep .ant-form-item-control-wrapper {
  width: 100%;
}

::v-deep .ant-checkbox-group {
  width: 100%;
  .ant-checkbox-group-item {
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
::v-deep #editer {
  tr {
    height: 28.5px;
  }
}
::v-deep .ant-table-thead {
  th {
    &:before {
      content: "*";
      color: red;
    }
  }

  th:nth-child(4) {
    &:before {
      content: "";
    }
  }
}
::v-deep .ant-select-dropdown {
  z-index: 99999;
}
::v-deep .ant-modal-close-x {
  margin: -13px -13px 0 0;
}
.upImg {
  width: 109px;
  height: 109px;
}

.informationBox > div {
  margin-top: 40px;
}
.informationBox > div:nth-child(1) {
  margin-top: 0px;
}
.tip {
  font-size: 12px;
  line-height: 20px;
}
</style>